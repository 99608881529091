import './navigation.css';
import * as React from "react";
import { NavigationType } from "./NavigationType";

export default function Navigation({ children }: NavigationType) {
    const [menuOpen, setMenuOpen] = React.useState(false);

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <nav>
            {/* Бургер-меню иконка */}
            <div className="burger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>

            <ul className={menuOpen ? "open" : ""}>
                {React.Children.map(children, child => {
                    if (React.isValidElement<{ onClick?: () => void }>(child)) {
                        return React.cloneElement(child, {
                            onClick: () => {
                                if (child.props.onClick) {
                                    child.props.onClick();
                                }
                                closeMenu();
                            }
                        });
                    }
                    return child;
                })}
            </ul>
        </nav>
    );
}

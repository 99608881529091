import Cards from "../../components/Card/Cards";
import Card from "../../components/Card/Card";
import Link from "../../components/Link/Link";
import Text from "../../components/Text/Text";
import React, {useEffect, useState} from "react";
import portfolio, {PortfolioData} from "../../api/portfolio";
import { ReactComponent as Globe} from '../../icons/Globe.svg';
import Images from "../../components/Images/Images";


const PortfolioPage =  () => {
    const [portfolios, setPortfolios] = useState<PortfolioData[]>([]);

    useEffect(() => {
        const fetchInstruction = async () => {
            try {
                const result = await portfolio();
                setPortfolios(result);
            } catch (error) {
                console.error("Ошибка загрузки инструкции:", error);
            }
        };

        fetchInstruction().catch((error) => {
            console.error("Ошибка при вызове fetchInstruction:", error);
        });
    }, []);


    return (
        <>
            <Cards>
                {
                    portfolios.map((portfolio, index) => (
                        <Card className="content-text card-center" key={index}>
                            <Images
                                image={portfolio.img}
                                height={'250em'}
                                width={'380px'}
                                />
                            <h1>{portfolio.name}</h1>
                            <Link
                                url={portfolio.url}
                                text={<Globe />}
                            />
                            <Text>
                                {portfolio.description}
                            </Text>
                        </Card>
                    ))
                }

            </Cards>
        </>
    );
}

export default PortfolioPage;

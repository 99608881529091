import Navigation from "./components/Navigation/Navigation";
import Item from "./components/Navigation/Item";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage/HomePage";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import IdeaPage from "./pages/IdeaPage/IdeaPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import './App.css';

const App =  () => {
    return (
        <Router>
            <Navigation>
                <Item name="Главная" href="/"/>
                <Item name="Мои проекты" href="/portfolio"/>
                <Item name="Обо мне" href="/about"/>
                <Item name="Идеи для партнёрства" href="/contacts"/>
            </Navigation>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/contacts" element={<IdeaPage />} />
                <Route path="/about" element={<AboutPage />} />
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;

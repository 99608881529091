import ContactForm from "../../components/ContactForm/ContactForm";
import Cards from "../../components/Card/Cards";
import Header from "../../components/Header/Header";

const IdeaPage =  () => {
    return (
        <>
            <Header name="Идеи для сотрудничества" />
            <Cards>
                <ContactForm />
            </Cards>
        </>
    );
}

export default IdeaPage;

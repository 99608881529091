import Cards from "../../components/Card/Cards";
import Card from "../../components/Card/Card";
import Image from "../../components/Images/Images";
import avatar from '../../storages/avatar.png';
import './HomePage.css';
import React from 'react';
import Contact from "../../components/Contact/Contact";
import Text from "../../components/Text/Text";

const HomePage =  () => {
    return (
        <>
        <Cards>
            <Card className="content-image">
                <Image
                    className="image-avatar"
                    image={avatar}
                    height={'320em'}
                    width={'320em'}
                />
            </Card>
            <Card className="content-text">
                <h1>Таркин Роман Станиславович</h1>
                <Contact />
                <Text>
                    Привет! Я веб-разработчик с более чем 3,5 года опыта в цифровой трансформации и
                    разработке ПО. Специализируюсь на PHP и fullstack-разработке, участвую в
                    проектировании и поддержке сложных систем, включая ERP и CRM.
                    Мои навыки охватывают современные технологии, такие как CI/CD, Docker и JavaScript.
                </Text>
            </Card>
        </Cards>
        </>
    );
}

export default HomePage;

import router from "./routes";
import {json} from "react-router-dom";

export interface ContactsData {
    result: boolean;
}

export type FormData = {
    name: string;
    email: string;
    company: string;
    idea: string;
};

export default async (data: FormData): Promise<ContactsData[]> => {
    const response = await fetch(router['contacts'], {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return (await response.json());
}